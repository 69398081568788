/**
 * Default page javascript. All other pages extend this one.
 */

//imports
import "lazysizes";
import "core-js/modules/es.promise";
//bootstrap imports
import "bootstrap/js/affix";
import "bootstrap/js/alert";
import "bootstrap/js/button";
import "bootstrap/js/carousel";
import "bootstrap/js/collapse";
import "bootstrap/js/dropdown";
import "bootstrap/js/modal";
import "bootstrap/js/scrollspy";
import "bootstrap/js/tab";
import "bootstrap/js/tooltip";
import "bootstrap/js/transition";

// Polyfill
import arrayFindPolyfill from "./arrayFind";

// Libs
import aosInit from "./aos";
import whenDomReady from "when-dom-ready";
import setIndicationCookie from "./setIndicationCookie";
import btnClickSetIndicationCookie from "./btnClickSetIndicationCookie";
import siteConfig from "./siteConfig";
import extend from "./extend";
import LoadScript from "../plugins/loadScript";
import signupForm from "./signupForm";
import documentMailer from "./documentMailer";
import References from "./references";
import BtnCollapsible from "./btnCollapsible";
import ImageComparisonSlider from "./imageComparisonSlider";
import CostCoverageTool from "./costCoverageTool";
import HeroTextAnimate from "./heroTextAnimate";
import TakeQuiz from "./takeQuiz";
import chart from "./chart";
import FilterGalleryForm from "./filterGalleryForm";
import FullPageTabs from "./fullPageTabs";
import EmailDocModalSubmit from "./emailDocModalSubmit";
import CarouselIndications from "./carouselIndications";
import Webinar from "./webinar";
import WebinarForm from "./webinarForm";
import createMOC from "./createMOC";
import GhostSignup from "./ghostSignupForm";
import videoGalleryTranscriptScroll from "./videoGalleryTranscriptScroll";
import patientStoriesAmbassador from "./patientStoriesAmbassador";
import loadWatsonOnScroll from "../utils/loadWatsonOnScroll";
import carouselInit from "./carousel";
import ddgTooltip from "./ddgTooltip";
import ddgSliders from "./ddgSliders";
import indicationListDropdownBand from "./indicationDropdownBand/indicationDropdownBand";
import ddgInit from "./ddg/ddgMain";
import flipToggleInit from "./flipToggle/flipToggleMain";
import slideQuiz from "./slideQuiz";
import flipCard from "./flipCard";
import imageCarouselHero from "./imageCarouselHero";
import "./autocompleteAlgolia";
import video from "./video";
import ViewTranscript from "./viewTranscript.js";
import isiInformation from "../stores/isiInformation";
import isi from "./isi";

//Core Libs
import CoreIsiSticky from "./coreIsiSticky";
import CoreEventRegistration from "./coreEventRegistration";
import CoreEventCancellation from "./coreEventCancellation";
import CoreGlossaryTooltips from "./coreGlossaryTooltips";
import CoreInterstitials from "./coreInterstitials";
import coreStickyHeader from "./coreStickyHeader";
import coreHeader from "./coreHeader";

import accessibilityInit from "./accessibility";

// Styles
import "../../css/pages/BasePage.scss";

//alpine
import AsyncAlpine from "async-alpine";
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import "../magics/loadScript";
import vimeoPlayer from "./video/vimeo";
import scrollGradient from "./scrollGradient";
import "../directives/swipe/index";

class BasePage {
    constructor(...opts) {
        extend(this, opts);
        window.Alpine = Alpine;
        Alpine.plugin(collapse);
        AsyncAlpine.init(Alpine);
        AsyncAlpine.data("flipCard", () => import("./flipCard"));
        AsyncAlpine.data("vimeoAudioPlayer", () => import("./audio/index.js"));
        AsyncAlpine.data("damAudioPlayer", () => import("./audio/damAudio.js"));
        AsyncAlpine.data("pixiChatVideos", () => import("./pixiChatVideos"));
        AsyncAlpine.data("carousel", () => import("./carousel/index.js"));
        AsyncAlpine.data("totalDropdown", () => import("./totalDropdown"));
        AsyncAlpine.data("quickPollSelection", () => import("./quickPoll/quickPollSelection.js"));
        AsyncAlpine.start();
        Alpine.data("vimeoPlayer", vimeoPlayer);
        Alpine.data("scrollGradient", scrollGradient);
        Alpine.store("isiInformation", isiInformation);
        Alpine.data("isi", isi);

        Alpine.start();
        this.init();
    }

    init() {
        const self = this;
        self.ready = false;
        self.onBeforeReady();
        setIndicationCookie.init();
        btnClickSetIndicationCookie.init();
        CoreIsiSticky.init();

        whenDomReady().then(function() {
            self.onReady();
        });

        window.addEventListener("load", function() {
            self.onLoad();
            new HeroTextAnimate();
            new chart();
            CarouselIndications.init();
            EmailDocModalSubmit.init();
            video.init();
            videoGalleryTranscriptScroll.init();
            CoreInterstitials.init();
            CoreGlossaryTooltips.init();
            coreStickyHeader();
            coreHeader();
            createMOC.init();
            GhostSignup.init();
            ddgTooltip();
            ddgSliders.sliderFunct();
            ddgSliders.selectOnlyNone();
            patientStoriesAmbassador.init();
            carouselInit();
            ddgInit();
            flipToggleInit();
            accessibilityInit();
            slideQuiz();
            flipCard();
            imageCarouselHero();
        });
    }

    onBeforeReady() {
        extend(this, siteConfig);
        arrayFindPolyfill();
        // load & append new script
        new LoadScript();
    }

    onReady() {
        const self = this;
        self.ready = true;
        new References();
        new signupForm();
        new BtnCollapsible();
        new ImageComparisonSlider();
        new CostCoverageTool();
        documentMailer();
        new TakeQuiz();
        new FilterGalleryForm();
        new FullPageTabs();
        new CoreEventRegistration();
        new CoreEventCancellation();
        new ViewTranscript();
        new Webinar();
        new WebinarForm();
        loadWatsonOnScroll.init();
        indicationListDropdownBand();
        aosInit.aosInit();
    }

    onLoad() {
        document.getElementsByTagName("body")[0].classList.add("loaded");
    }

    isReady() {
        var self = this;
        return self.ready === true;
    }

    noop() {}
}

export default BasePage;
